@font-face {
    font-family: "Madera";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("Monotype-MaderaLight.otf") format("opentype");
}
@font-face {
    font-family: "Madera";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("Monotype-MaderaMedium.otf") format("opentype");
}
@font-face {
    font-family: "Madera";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("Monotype-MaderaMedium.otf") format("opentype");
}

@font-face {
    font-family: "Madera";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("Monotype-MaderaBold.otf") format("opentype");
}

@font-face {
    font-family: "Madera";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("Monotype-MaderaBold.otf") format("opentype");
}

@font-face {
    font-family: "Madera";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("Monotype-MaderaExtraBold.otf") format("opentype");
}