@import url('assets/fonts/madera.scss');
@import url('assets/styles/Privacy-Icons-Regular.min.css');
@import 'src/assets/styles/mixins';
@import 'assets/styles/bootstrap.min.css';
@import 'assets/styles/material.scss';


:root {
  /* --unnamed-color-ffffff: #ffffff;
  --unnamed-color-f5f5f5: #f5f5f5;
  --unnamed-color-efefef: #efefef;
  --unnamed-color-d8d8d8: #d8d8d8;
  --unnamed-color-cbcbcb: #cbcbcb;
  --unnamed-color-4f4f4f: #4f4f4f;

  --line: #606060;

  --primary-light: #7dc0ff;
  --primary: #5092ce;
  --primary-dark: #265582;

  --accent: #28a681;

  --buttons-inputs: #2e2e2e;
  --text-color: #222222;
  --bg-dark: #181818;
  --warn-light: #ffefe8;
  --warn-color: #ee6532;
  --warn-dark: #963e1e;

  --main-background-color: var(--unnamed-color-ffffff);
  --header-text-color: var(--primary);
  --icon-color: var(--primary);
  --link-text-color: var(--primary);
  --menu-divider-color: var(--primary);
  --input-border-color: var(--text-color);

  --primary-btn-color: var(--primary);
  --btn-text-color: var(--primary);
  --btn-primary-text-color: var(--unnamed-color-ffffff);
  --button-text-color-disabled: var(--unnamed-color-d8d8d8);
  --disabled-btn-text-color: var(--unnamed-color-4f4f4f);

  --mobile-padding: 20px;

  @media (prefers-color-scheme: dark) {
      --primary: #ce7250;
  } */

  /* Colors: */
  --unnamed-color-ffffff: #ffffff;
  --unnamed-color-ff4b75: #ff4b75;
  --unnamed-color-f0f0f0: #f0f0f0;
  --unnamed-color-add7b4: #add7b4;
  --unnamed-color-5091cd: #5091cd;
  --unnamed-color-5191ce: #5191CE;
  --unnamed-color-f8aace: #f8aace;
  --unnamed-color-6d9442: #6d9442;
  --unnamed-color-00426c: #00426c;
  --unnamed-color-ee6532: #ee6532;
  --unnamed-color-a58300: #a58300;
  --unnamed-color-222222: #010000;

  /* Font/text values */
  --unnamed-font-family-madera: Madera;
  --unnamed-font-style-normal: normal;
  --unnamed-font-weight-medium: medium;
  --unnamed-font-size-15: 15px;
  --unnamed-font-size-20: 20px;
  --unnamed-font-size-25: 25px;
  --unnamed-font-size-30: 30px;
  --unnamed-font-size-40: 40px;
  --unnamed-font-size-45: 45px;
  --unnamed-font-size-50: 50px;
  --unnamed-font-size-90: 90px;
  --unnamed-character-spacing-0: 0px;
  --unnamed-character-spacing-0-1: 0.1px;
  --unnamed-character-spacing-0-2: 0.2px;
  --unnamed-line-spacing-23: 23px;
  --unnamed-line-spacing-30: 30px;
  --unnamed-line-spacing-38: 38px;
  --unnamed-line-spacing-45: 45px;
  --unnamed-line-spacing-55: 55px;
  --unnamed-text-transform-uppercase: uppercase;

  // custom color tokens
  --warn-color: var(--unnamed-color-ff4b75);
  --main-bg-color: var(--unnamed-color-ffffff);
  --primary: var(--unnamed-color-5091cd);
  --primary-dark: var(--unnamed-color-00426c);

  --button-color: var(--unnamed-color-ffffff);
  --button-color-primary: var(--primary);

  --button-border-color: var(--primary);
  --button-border-color-primary: var(--primary);

  --button-text-color: var(--primary);
  --button-text-color-primary: var(--unnamed-color-ffffff);
  --button-text-color-disabled: var(--unnamed-color-222222);

  --text-color: var(--unnamed-color-222222);
  --text-color-primary: var(--primary);
  --text-color-secondary: var(--unnamed-color-ffffff);
  --link-text-color: var(--primary);

  --input-color: var(--unnamed-color-ffffff);
  --input-border-color: var(--unnamed-color-222222);
  --input-text-color: var(--unnamed-color-222222);

  // global sizes
  --page-max-width-desktop: 1600px;
  --left-right-padding-desktop: 45px;
  --bottom-padding-desktop: 50px;
  --page-max-width-mobile: 100%;
  --left-right-padding-mobile: 15px;
  --bottom-padding-mobile: 30px;


  // header clipping
  --clip-right: 44px;
  --clip-width: 58px;
  --clip-height: 100px;

  scroll-behavior: auto;
}

* {
  outline: none;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: Madera, 'Helvetica Neue', sans-serif;
}

body {
  overflow: auto;
  overflow-x: hidden;
}


// Text and list

h1,
h2,
h3,
h4,
h5,
h6,
div,
span,
li,
small,
input,
textarea,
select,
p,
strong {
  color: var(--text-color);
  font-family: Madera, 'Helvetica Neue', sans-serif;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

h1 {
  font-size: 25px;
  line-height: 38px;

  @include md {
    font-size: 45px;
    line-height: 55px;
  }
}

.fa,
.fac,
.fas,
.fal,
.far,
.fab {
  color: var(--text-color-primary);
}

small {
  color: var(--text-color);
  font-size: 12px;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

// Form controls

label {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  span.text {
    font-weight: 600;
  }
}

a,
.link {
  color: var(--link-text-color);
  text-decoration: none;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

button {
  outline: none;
  background-color: var(--button-color);

  display: flex;
  flex-direction: row;
  column-gap: 1em;
  align-items: center;
  padding: 19px 20px;
  justify-content: center;

  border: solid 2px var(--button-border-color);
  color: var(--button-text-color);
  font-size: 18px;
  line-height: 1;

  i,
  span {
    color: var(--button-text-color);
    font-size: 18px;
    line-height: 1;
    text-transform: uppercase;
  }
}

button:active {
  border-color: var(--primary-dark);

  i,
  span {
    color: var(--primary-dark);
  }
}

button:disabled {
  cursor: default;
  // background-color: var(--button-text-color-disabled);
  border-color: var(--button-text-color-disabled);
  color: var(--button-text-color-disabled);
  opacity: 0.5;

  i,
  span {
    color: var(--button-text-color-disabled);
  }
}

button:active:disabled {
  background-color: none;
}

button:disabled:hover {
  box-shadow: none;
}

button.primary {
  background-color: var(--button-color-primary);

  i,
  span {
    color: var(--button-text-color-primary);
  }
}

button.primary:active {
  background-color: var(--primary-dark);

  i,
  span {
    color: var(--button-text-color-primary);
  }
}

button.primary:disabled {
  background-color: var(--button-text-color-disabled);
}

button.primary:hover, button.button:hover {
  box-shadow: 0 0 5px var(--primary);
}

button.borderless {
  border: none;
  padding-left: 0;
  padding-right: 0;
  text-decoration: underline;
}

button.borderless:hover {
  box-shadow: none;
}

/* .button {
    user-select: none;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--primary);
    height: 43px;
    border-radius: 4px;
    color: var(--primary);
    background: transparent;
    font: 600 18px/23px Madera;

    @include md {
        min-width: 336px;
    }

    &:hover {
        border: 2px solid var(--primary-dark);

        span,
        i {
            color: var(--primary-dark);
        }
    }

    &:focus {
        box-shadow: 0 0 12px var(--primary);
    }

    &:disabled,
    &:disabled:active {
        border: 2px solid var(--unnamed-color-d8d8d8);
        color: var(--unnamed-color-d8d8d8);

        span,
        i {
            color: var(--unnamed-color-d8d8d8);
        }
    }

    &:active {
        border: 2px solid var(--primary-dark);

        span,
        i {
            color: var(--text-color);
        }
    }

    &[type='submit'] {
        background: var(--primary);
        color: white;

        &:hover {
            border: 2px solid var(--primary-dark);
            background: var(--primary-dark);
        }

        &:disabled {
            pointer-events: none;
            background: var(--unnamed-color-d8d8d8);
        }

        &:active {
            background: var(--text-color);
        }

        i {
            color: white;
        }

        span {
            color: white;
        }
    }

    span {
        color: var(--primary);
        pointer-events: none;
    }

    i {
        margin-right: 10px;
    }
} */

a {
  text-decoration: none;
  color: var(--link-text-color);

  span {
    color: var(--link-text-color);
  }

  &:link {
    color: var(--link-text-color);
  }

  &:hover {
    color: var(--primary-dark);

    span {
      color: var(--primary-dark);
    }
  }

  &:active {
    color: var(--text-color);

    span {
      color: var(--text-color);
    }
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  @include md {
    max-width: var(--page-max-width-desktop);
    width: 100%;
    padding-top: 40px
  }
}

.page-header {
  color: var(--primary);
  margin-bottom: 15px;

  @include md {
    margin-bottom: 35px;
  }

  /* padding-top: 0;
  padding-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;

  @include md {
      padding-left: 0;
      padding-right: 0;
  }

  h4 {
      font-size: 22px;
      margin-bottom: 10px;

      @include md {
          font-size: 36px;
          font-weight: 700;
      }
  }

  h5 {
      font-size: 20px;
      margin-bottom: 10px;

      @include md {
          font-size: 28px;
          font-weight: 700;
      }
  }

  p {
      font-size: 16px;
      margin-bottom: 40px;

      @include md {
          font-size: 22px;
      }
  }

  small {
      font-size: 14px;
      display: block;
      margin-bottom: 20px;

      @include md {
          font-size: 18px;
      }
  } */
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: transparent !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-frame {
  border-color: var(--primary) !important;
}

.mat-checkbox-frame {
  border-width: 1px !important;
}

.mat-checkbox-checkmark-path {
  stroke: var(--primary) !important;
}

.mat-checkbox-layout {
  flex-direction: row;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
.mat-radio-ripple
.mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background: var(--primary);
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--primary);
}

.mat-radio-label {
  flex-direction: row !important;
}

.mat-radio-container {
  width: 18px !important;
  height: 18px !important;
}

.mat-radio-inner-circle {
  width: 18px !important;
  height: 18px !important;
}

.mat-radio-outer-circle {
  width: 18px !important;
  height: 18px !important;
  border-width: 1px !important;
}

.mat-calendar-table {
  .mat-calendar-table-header-divider::after {
    height: 0;
  }

  [aria-hidden='true'] .mat-calendar-body-label {
    display: none;
  }

  td.mat-calendar-body-label {
    visibility: hidden;
  }
}

.mat-calendar-body-selected {
  background: var(--primary) !important;
}

.mat-calendar-body-cell-content {
  border-width: 0 !important;
  font: 500 18px/23px Madera;
}

.mat-calendar-table-header th {
  font: 500 18px/23px Madera;
  color: #757575;
}

.mat-datepicker-content .mat-calendar {
  height: auto !important;
}

.no-items,
.error-msg {
  display: flex;
  height: 130px;
  width: 100%;

  align-items: center;
  justify-content: center;
  color: var(--unnamed-color-4f4f4f);
}

.no-items {
  color: var(--unnamed-color-4f4f4f);
}

.error-msg {
  color: var(--primary-dark);
}

.dropdown-expanded-bg-gray {
  .dropdown-wrapper__body {
    background-color: var(--unnamed-color-efefef);
  }
}

.dropdown-padding-20 {
  .dropdown-wrapper__head {
    margin-left: 20px;
    margin-right: 20px;
  }

  .dropdown-wrapper__body {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  @include md {
    .dropdown-wrapper__head {
      margin-left: 0;
      margin-right: 0;
    }
    .dropdown-wrapper__body {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }
  }
}

.dropdown-wrapper.dropdown-light {
  width: 50%;
  margin-bottom: 5px;

  .dropdown-wrapper__head {
    padding: 0 0 5px 0;
    border-bottom: solid 2px var(--unnamed-color-efefef);

    &__title {
      font-weight: 500;
    }
  }

  .dropdown-wrapper__body + mat-divider {
    display: none;
  }
}

.mat-snack-bar-error {
  --mdc-snackbar-container-color: var(--warn-color);
  color: var(--text-color-secondary);

  div, span {
    color: var(--text-color-secondary);
  }
}

.circle {
  margin-right: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;

  &.green {
    background: #00ff58;
    box-shadow: 0 0 5px #1aff69;
  }

  &.dark-green {
    background: #629d76;
    box-shadow: 0 0 5px #629d76;
  }

  &.red {
    background: #d02c2e;
    box-shadow: 0 0 5px #d02c2e;
  }

  &.yellow {
    background: #ffe941 0 0 no-repeat padding-box;
    box-shadow: 0 0 5px #ffe941;
  }

  &.blue {
    background: #7ccdff;
    box-shadow: 0 0 5px #7ccdff;
  }
}

/* Styles from Adobe XD */

/* Character Styles */
.unnamed-character-style-1 {
  font-family: var(--unnamed-font-family-madera);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-90);
  line-height: var(--unnamed-line-spacing-55);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-5091cd);
}

.unnamed-character-style-2 {
  font-family: var(--unnamed-font-family-madera);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-20);
  line-height: var(--unnamed-line-spacing-30);
  letter-spacing: var(--unnamed-character-spacing-0-2);
  color: var(--unnamed-color-ee6532);
  text-transform: var(--unnamed-text-transform-uppercase);
}

.unnamed-character-style-3 {
  font-family: var(--unnamed-font-family-madera);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-20);
  line-height: var(--unnamed-line-spacing-30);
  letter-spacing: var(--unnamed-character-spacing-0-1);
  color: var(--unnamed-color-222222);
}

.unnamed-character-style-4 {
  font-family: var(--unnamed-font-family-madera);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-45);
  line-height: var(--unnamed-line-spacing-55);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-222222);
}

.unnamed-character-style-5 {
  font-family: var(--unnamed-font-family-madera);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-40);
  line-height: var(--unnamed-line-spacing-55);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-222222);
}

.unnamed-character-style-6 {
  font-family: var(--unnamed-font-family-madera);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-30);
  line-height: var(--unnamed-line-spacing-45);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-222222);
}

.unnamed-character-style-7 {
  font-family: var(--unnamed-font-family-madera);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-50);
  line-height: var(--unnamed-line-spacing-38);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-222222);
}

.unnamed-character-style-8 {
  font-family: var(--unnamed-font-family-madera);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-25);
  line-height: var(--unnamed-line-spacing-38);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-222222);
}

.unnamed-character-style-9 {
  font-family: var(--unnamed-font-family-madera);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-15);
  line-height: var(--unnamed-line-spacing-23);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-222222);
}

.unnamed-character-style-10 {
  font-family: var(--unnamed-font-family-madera);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: var(--unnamed-font-size-20);
  line-height: var(--unnamed-line-spacing-30);
  letter-spacing: var(--unnamed-character-spacing-0-1);
  color: var(--unnamed-color-222222);
  text-transform: var(--unnamed-text-transform-uppercase);
}

.shopping-cart-dropdown-panel {
  width: 100%;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  border-bottom: 1.5px solid var(--primary);
  padding: 35px var(--left-right-padding-mobile) 25px var(--left-right-padding-mobile);

  @include md {
    border-top: 1px solid var(--primary);
    max-width: 320px;
    padding-right: 0;
  }
}

.backdrop-transparent {
  background: transparent;
}

.checkout-wrapper.booking {
  checkout-core {
    min-height: calc(100vh - 183px);
  }
}

.checkout-wrapper.booking-web-view {
  checkout-core {
    min-height: calc(100vh - 20px);
  }
}