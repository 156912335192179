// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import './custom-palette.scss';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

/* $mat-custom: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: red,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
); */

$palette-primary: mat.m2-define-palette($discover-swiss-mat-red);
$palette-accent: mat.m2-define-palette($discover-swiss-mat-red);

// The warn palette is optional (defaults to red).
$palette-error: mat.m2-define-palette(mat.$m2-red-palette);
$palette-success: mat.m2-define-palette(mat.$m2-light-green-palette);
$palette-info: mat.m2-define-palette(mat.$m2-cyan-palette);
$palette-warn: mat.m2-define-palette(mat.$m2-amber-palette);

$app-typography: mat.m2-define-typography-config(
        $font-family: 'Madera, "Helvetica Neue", sans-serif',
        $body-1: mat.m2-define-typography-level(16px, 1.5, 500),
        $body-2: mat.m2-define-typography-level(16px, 1.5, 500),
        $button: mat.m2-define-typography-level(16px, 1.5, 500),
        $caption: mat.m2-define-typography-level(16px, 1.5, 500),
        $overline: mat.m2-define-typography-level(16px, 1.5, 500),
        $headline-1: mat.m2-define-typography-level(16px, 1.5, 500),
        $headline-2: mat.m2-define-typography-level(16px, 1.5, 500),
        $headline-3: mat.m2-define-typography-level(16px, 1.5, 500),
        $headline-4: mat.m2-define-typography-level(16px, 1.5, 500),
        $headline-5: mat.m2-define-typography-level(16px, 1.5, 500),
        $headline-6: mat.m2-define-typography-level(16px, 1.5, 500),
);

// Create the theme object (a Sass map containing all of the palettes).
$theme: mat.m2-define-light-theme((
        color: (
                primary: $palette-primary,
                accent: $palette-accent,
                error: $palette-error
        ),
        typography: $app-typography
));

$primary: mat.m2-get-color-from-palette($palette-primary);
$primary-light: mat.m2-get-color-from-palette($palette-primary, 200);
$primary-contrast: mat.m2-get-contrast-color-from-palette($palette-primary, 500);
$accent: mat.m2-get-color-from-palette($palette-accent);

$error: mat.m2-get-color-from-palette($palette-error);
$success: mat.m2-get-color-from-palette($palette-success);
$warn: mat.m2-get-color-from-palette($palette-warn, 600);
$info: mat.m2-get-color-from-palette($palette-info, 600);

$gray3: #333333;
$gray6: #666666;
$gray8: #888888;
$grayC: #cccccc;
$grayD: #dddddd;
$grayE: #eeeeee;
$transparentWhite6: rgba(255, 255, 255, 0.6);
$transparentBlack6: rgba(0, 0, 0, 0.6);
$transparentBlack3: rgba(0, 0, 0, 0.3);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);

/* You can add global styles to this file, and also import other style files */

html {
  // form-field
  --mdc-filled-text-field-container-color: white;
  --mat-form-field-hover-state-layer-opacity: 0.01;
  --mat-form-field-focus-state-layer-opacity: 0.01;
  --mat-form-field-trailing-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-filled-text-field-focus-label-text-color: var(--mdc-filled-text-field-label-text-color);
  --mdc-filled-text-field-focus-active-indicator-height: 0px;
  --mdc-filled-text-field-active-indicator-height: 0px;
  --mdc-filled-text-field-container-shape: 0px;
  
  --mdc-snackbar-container-color: #fff;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-form-field-invalid {
  .mdc-text-field--filled {
    border-color: var(--warn-color);
  }
}

.mdc-text-field--filled {
  border: 2px solid;
}
